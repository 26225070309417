import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { Layout, Card, Avatar, Typography, Space, Table, Tag, Button, Row, Col } from 'antd';
import { 
  MailOutlined, 
  PhoneOutlined, 
  HomeOutlined, 
  UserOutlined, 
  CalendarOutlined,
  BookOutlined 
} from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { QRCodeCanvas } from 'qrcode.react';
import getUserIdFromToken from '../../Utils/authUtils';
import Modal from '../../components/Modal';
import moment from 'moment';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import './Student.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Content } = Layout;
const { Title, Text } = Typography;

function StudentDashboard() {
  const { t, i18n } = useTranslation(); // Use translation hook
  const userId = getUserIdFromToken();
  const [student, setStudent] = useState(null);
  const [studentTestRegistrations, setStudentTestRegistrations] = useState([]);
  const [error, setError] = useState(null);
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [popupData, setPopupData] = useState(null);
  const [absentCounts, setAbsentCounts] = useState({});

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const API_URL = process.env.REACT_APP_API_URL;

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };


  const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      const daysOfWeek = t('daysOfWeek', { returnObjects: true });
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return t('invalidDay');
    }
  };
  useEffect(() => {
    // Fetch student data
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const studentData = response.data;
        setStudent({
          ...studentData,
          photourl: studentData.photourl || 'https://via.placeholder.com/150',
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
        setError('Failed to fetch student data.');
      }
    };

    // Fetch student attendance data
    const fetchStudentAttendance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentAttendance(response.data);
        calculateAbsences(response.data); // Calculate absences when fetching data
      } catch (error) {
        console.error('Error fetching student attendance:', error);
      }
    };

    // Function to calculate absences
    const calculateAbsences = (attendanceData) => {
      const absences = {};
      attendanceData.forEach(attendance => {
        const statusLowerCase = attendance.status.toLowerCase();
        if (statusLowerCase === 'absent') {
          if (!absences[attendance.class_code]) {
            absences[attendance.class_code] = 1;
          } else {
            absences[attendance.class_code] += 1;
          }
        }
      });
      setAbsentCounts(absences);
    };

    const fetchBillingHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/user`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const { registrations } = response.data;
  
        // Sort schedules for each registration
        const sortedRegistrations = registrations.map(reg => {
          const sortedSchedule = reg.schedule.sort((a, b) => {
            const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm:ss');
            const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm:ss');
            return dateTimeA - dateTimeB;
          });
  
          return { ...reg, schedule: sortedSchedule };
        });
  
        // Sort registrations by the earliest class in their schedule
        sortedRegistrations.sort((a, b) => {
          const earliestA = a.schedule[0] ? moment(`${a.schedule[0].date} ${a.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          const earliestB = b.schedule[0] ? moment(`${b.schedule[0].date} ${b.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          return earliestA - earliestB;
        });
  
        setBillingHistory(sortedRegistrations);
      } catch (error) {
        console.error('Error fetching billing history:', error);
      }
    };

    // Fetch student test registrations
    const fetchStudentTestRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/test-scores/test-registrations`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentTestRegistrations(response.data); // Store fetched test registrations in state
      } catch (error) {
        console.error('Error fetching student test registrations:', error);
      }
    };

    // Call the functions
    fetchStudentData();
    fetchStudentAttendance();
    fetchBillingHistory();
    fetchStudentTestRegistrations(); // Fetch test registrations

  }, [userId, API_URL]);



  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);

    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;

    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }

    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  }, [i18n.language]);

  useEffect(() => {
    const recentDate = new Date();
    recentDate.setDate(recentDate.getDate() - 7);
  
    // Function to get dismissed toasts from localStorage
    const getDismissedToasts = () => {
      const dismissed = localStorage.getItem('dismissedToasts');
      return dismissed ? JSON.parse(dismissed) : [];
    };
  
    // Function to save dismissed toasts to localStorage
    const saveDismissedToasts = (dismissedToasts) => {
      localStorage.setItem('dismissedToasts', JSON.stringify(dismissedToasts));
    };
  
    const dismissedToasts = getDismissedToasts();
  
    studentAttendance.forEach(attendance => {
      const attendanceDate = new Date(attendance.timestamp);
  
      // Check if the attendance is within the last 7 days and the student was absent
      if (attendanceDate >= recentDate && attendance.status.toLowerCase() === 'absent') {
        const toastId = `${attendance.class_code}_${attendance.timestamp}`;
        
        // Check if this toast has already been dismissed
        if (!dismissedToasts.includes(toastId)) {
          const count = absentCounts[attendance.class_code] || 1;
  
          toast.info(
            `You were marked absent for class ${attendance.class_code} on ${formatDate(attendance.timestamp)}. This is your ${count}${getOrdinalSuffix(count)} absence for this class.`,
            {
              position: "top-right",
              autoClose: true,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: 'absent-toast',
              style: {
                backgroundColor: '#f8d7da',
                color: '#721c24',
                border: '1px solid #f5c6cb',
              },
              // When the toast is closed, add it to the dismissed toasts
              onClose: () => {
                const updatedDismissedToasts = [...dismissedToasts, toastId];
                saveDismissedToasts(updatedDismissedToasts);
              },
            }
          );
        }
      }
    });
  }, [studentAttendance, absentCounts, formatDate]);
  
  
   // student's upcoming tests
   useEffect(() => {
    const today = new Date();
  
    studentTestRegistrations.forEach(test => {
      const testDate = new Date(test.test_date);
      const daysBeforeTest = Math.ceil((testDate - today) / (1000 * 60 * 60 * 24));
  
      if (daysBeforeTest === 1) {
        toast.info(
          `Reminder: Your ${test.test_type} (${test.sub_type}) test is scheduled on ${formatDate(test.test_date)}.`,
          {
            position: "top-right",
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: '#f8d7da', // Light red background
              border: '1px solid red', // Red border
              color: 'black',
            },
            icon: '⚠️',
          }
        );
      }
    });
  }, [studentTestRegistrations, formatDate]);
  
  
   // Function to get the ordinal suffix for numbers (1st, 2nd, 3rd, etc.)
   const getOrdinalSuffix = (i) => {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  };

  
  const openSchedulePopup = (data) => {
    setPopupData(data);
  };
  

  const closePopup = () => {
    setPopupData(null);
  };

  const sortByDateAscending = (schedule) => {
    return schedule.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const filterFutureClasses = (schedule) => {
    // Return the schedule without filtering for future classes
    return schedule;
  };

  const filteredAndSortedBillingHistory = useMemo(() => {
    return billingHistory.map(enrollment => ({
      ...enrollment,
      schedule: sortByDateAscending(filterFutureClasses(enrollment.schedule))
    })).filter(enrollment => enrollment.schedule.length > 0);
  }, [billingHistory]);

  const sortedAndFilteredSchedule = useMemo(() => {
    return filteredAndSortedBillingHistory
      .flatMap(enrollment => 
        enrollment.schedule.map(schedule => ({ ...schedule, class_code: enrollment.class_code }))
      )
      .sort((a, b) => moment(a.date + ' ' + a.time, 'YYYY-MM-DD HH:mm:ss') - moment(b.date + ' ' + b.time, 'YYYY-MM-DD HH:mm:ss'));
  }, [filteredAndSortedBillingHistory]);

  const paginatedSchedule = useMemo(() => {
    return sortedAndFilteredSchedule.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [sortedAndFilteredSchedule, currentPage, itemsPerPage]);

  // Calculate total pages
  const totalPages = Math.ceil(sortedAndFilteredSchedule.length / itemsPerPage);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const eventContent = (eventInfo) => {
    const startTime = eventInfo.event.startStr.split('T')[1].slice(0, 5);
    const endTime = eventInfo.event.endStr.split('T')[1].slice(0, 5);
    const { subject, teacher, classroom } = eventInfo.event.extendedProps; // Extract classroom
  
    return (
      <div style={{ 
        backgroundColor: eventInfo.event.backgroundColor, 
        color: eventInfo.event.textColor, 
        padding: '5px', 
        borderRadius: '3px',
        fontSize: '0.8em', // Slightly reduce font size to fit more content
        lineHeight: '1.2' // Tighten line height for compactness
      }}>
        <div style={{ fontWeight: 'bold' }}>{`${startTime} - ${endTime}`}</div>
        <div>{eventInfo.event.title}</div>
        <div>{subject}</div>
        <div>{teacher}</div>
        <div>{classroom && `Room: ${classroom}`}</div> {/* Add classroom here */}
      </div>
    );
};


  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <Header />
        <ToastContainer />
        <Content style={{ padding: '24px' }}>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <Card 
                bordered={false}
                style={{ 
                  borderRadius: '16px',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
                }}
              >
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  padding: '24px'
                }}>
                  {/* Profile Header */}
                  <div style={{
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: '32px'
                  }}>
                    <Avatar 
                      size={120} 
                      src={student.photourl} 
                      icon={<UserOutlined />}
                      style={{
                        border: '4px solid #fff',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                        marginBottom: '16px'
                      }}
                    />
                    <Title level={3} style={{ margin: '8px 0' }}>
                      {student.firstname} {student.lastname}
                    </Title>
                    <Tag color="blue" style={{ padding: '4px 12px' }}>
                      {student.schoolid}
                    </Tag>
                  </div>
  
                  {/* Profile Details */}
                  <div style={{ width: '100%' }}>
                    {[
                      { 
                        icon: <MailOutlined style={{ color: '#1890ff' }} />, 
                        label: t('email'), 
                        value: student.email 
                      },
                      { 
                        icon: <PhoneOutlined style={{ color: '#52c41a' }} />, 
                        label: t('phone'), 
                        value: student.phone 
                      },
                      { 
                        icon: <HomeOutlined style={{ color: '#faad14' }} />, 
                        label: t('address'), 
                        value: student.address 
                      },
                      { 
                        icon: <CalendarOutlined style={{ color: '#eb2f96' }} />, 
                        label: t('dob'), 
                        value: formatDate(student.date_of_birth)
                      },
                      { 
                        icon: <BookOutlined style={{ color: '#722ed1' }} />, 
                        label: t('currentEducationLevel'), 
                        value: student.currenteducationlevel 
                      }
                    ].map((item, index) => (
                      <div 
                        key={index}
                        style={{
                          padding: '16px',
                          marginBottom: '12px',
                          background: '#f5f5f5',
                          borderRadius: '12px',
                          transition: 'all 0.3s ease'
                        }}
                        onMouseEnter={e => e.currentTarget.style.transform = 'translateX(8px)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'translateX(0)'}
                      >
                        <Space align="center">
                          <div style={{
                            padding: '8px',
                            borderRadius: '8px',
                            background: '#fff',
                            marginRight: '12px'
                          }}>
                            {item.icon}
                          </div>
                          <div>
                            <Text type="secondary" style={{ fontSize: '12px', display: 'block' }}>
                              {item.label}
                            </Text>
                            <Text strong style={{ fontSize: '14px' }}>
                              {item.value}
                            </Text>
                          </div>
                        </Space>
                      </div>
                    ))}
                  </div>
  
                  {/* QR Code Section */}
                  <div style={{ 
                    marginTop: '24px',
                    padding: '16px',
                    background: '#f5f5f5',
                    borderRadius: '12px',
                    textAlign: 'center'
                  }}>
                    <Text type="secondary" style={{ display: 'block', marginBottom: '12px' }}>
                      {t('Student ID')}
                    </Text>
                    <QRCodeCanvas 
                      value={student.schoolid} 
                      size={120}
                      style={{
                        padding: '8px',
                        background: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.06)'
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={24} lg={16}>
            <Card 
              title={t('myupcomingtest')} 
              style={{ marginBottom: '24px' }}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                {studentTestRegistrations.length > 0 ? (
                  studentTestRegistrations.map((test, index) => (
                    <Card
                      key={index}
                      type="inner"
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                      }}
                    >
                      <div style={{ marginBottom: '16px' }}>
                        <Title level={5}>{test.test_type} - {test.sub_type}</Title>
                        <Text style={{ display: 'block', marginBottom: '8px' }}>
                          {t('Test Date')}: {new Date(test.test_date).toLocaleDateString()}
                        </Text>
                        <Tag color={test.paid_status === 'paid' ? 'success' : 'error'}>
                          {t('Status')}: {test.paid_status === 'paid' ? t('Paid') : t('Unpaid')}
                        </Tag>
                      </div>
                    </Card>
                  ))
                ) : (
                  <Text>{t('No upcoming tests found.')}</Text>
                )}
              </div>
            </Card>
            </Col>
          </Row>

              <Card title={t('calendar')} style={{ marginBottom: '24px' }}>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  initialView="dayGridMonth"
                  locale={i18n.language === 'th' ? thLocale : 'en'}
                  events={billingHistory.flatMap(enrollment =>
                    enrollment.schedule.map(cls => {
                      const startTime = cls.time ? cls.time.slice(0, 5) : '00:00';
                      const startDateTime = cls.date ? `${cls.date}T${startTime}:00` : null;
                      const endTime = cls.time && cls.hours ? calculateEndTime(cls.time, cls.hours) : '00:00';
                      const endDateTime = cls.date ? `${cls.date}T${endTime}:00` : null;
  
                      if (!startDateTime || !endDateTime) {
                        console.warn('Missing time or date in class schedule:', cls);
                        return null;
                      }
  
                      return {
                        title: `${enrollment.class_code}`,
                        start: startDateTime,
                        end: endDateTime,
                        color: '#005181',
                        backgroundColor: '#005181',
                        borderColor: '#005181',
                        textColor: '#ffffff',
                        display: 'block',
                        extendedProps: {
                          subject: cls.subject,
                          teacher: cls.teacher,
                          classroom: cls.classroom,
                        },
                      };
                    }).filter(event => event !== null)
                  )}
                  eventDisplay="block"
                  eventColor="#005181"
                  eventBackgroundColor="#005181"
                  eventBorderColor="#005181"
                  eventTextColor="#ffffff"
                  eventContent={eventContent}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek',
                  }}
                  height="auto"
                  slotEventOverlap={false}
                  displayEventTime={true}
                  displayEventEnd={true}
                />
              </Card>
              <Card title={t('classSchedule')} style={{ marginBottom: '24px' }}>
                  <div style={{ overflowX: 'auto' }}>
                    <Table
                      dataSource={paginatedSchedule}
                      pagination={{
                        current: currentPage,
                        total: totalPages * itemsPerPage,
                        onChange: handlePageChange,
                      }}
                      columns={[
                        { title: t('date'), dataIndex: 'date', render: (date) => formatDate(date) },
                        { title: t('classCode'), dataIndex: 'class_code' },
                        { title: t('subject'), dataIndex: 'subject' },
                        { title: t('teacher'), dataIndex: 'teacher' },
                        {
                          title: t('time'),
                          render: (_, cls) => `${formatTime(cls.time)} - ${calculateEndTime(cls.time, cls.hours)}`,
                        },
                        { title: t('hours'), dataIndex: 'hours', render: (hours) => `${hours} ${t('hr')}` },
                        {
                          title: t('attendance'),
                          render: (_, cls) => {
                            const attendance = studentAttendance.find((att) => att.classid === cls.classid);
                            const status = attendance ? attendance.status : '';
                            const colors = { Present: 'green', Absent: 'red', Late: 'orange' };
                            return status ? (
                              <Tag color={colors[status] || 'default'}>{status}</Tag>
                            ) : (
                              <Tag>{status}</Tag>
                            );
                          },
                        },
                        {
                          title: t('homework'),
                          render: (_, cls) => {
                            // Find the attendance entry for the given class
                            const attendance = studentAttendance.find((att) => att.classid === cls.classid);
                            const homework = attendance ? attendance.homework : 'N/A';
                        
                            // Define styles for homework statuses
                            const homeworkStyles = {
                              YES: {
                                color: '#389e0d',
                                backgroundColor: '#f6ffed',
                                border: '1px solid #b7eb8f',
                              },
                              NO: {
                                color: '#cf1322',
                                backgroundColor: '#fff1f0',
                                border: '1px solid #ffa39e',
                              },
                              'N/A': {
                                color: '#8c8c8c',
                                backgroundColor: '#f5f5f5',
                                border: '1px solid #d9d9d9',
                              },
                            };
                        
                            return (
                              <Tag
                                style={{
                                  ...homeworkStyles[homework] || homeworkStyles['N/A'],
                                  borderRadius: '12px',
                                  padding: '2px 12px',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                }}
                              >
                                {homework}
                              </Tag>
                            );
                          },
                        }                        
                      ]}
                      scroll={{ x: 'max-content' }}
                    />
                  </div>
                </Card>

  
          <Card title={t('currentEnrollments')}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
              {billingHistory.map((enrollment, index) => (
                <Card
                  key={index}
                  type="inner"
                  title={enrollment.class_code}
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '16px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                  }}
                >
                  <div style={{ marginBottom: '16px' }}>
                    <p><strong>{t('Subjects')}:</strong> {enrollment.subjects.map(subject => subject.title).join(', ')}</p>
                    <p><strong>{t('Total Hours')}:</strong> {enrollment.total_hours}</p>
                  </div>
                  <div style={{ marginTop: 'auto', textAlign: 'center' }}>
                    <Link to={`/classdetailsstudent/${enrollment.groupid}`}>
                      <Button type="primary">{t('viewDetails')}</Button>
                    </Link>
                  </div>
                </Card>
              ))}
            </div>
          </Card>
  
          <Modal
            title={t('scheduleDetails')}
            open={!!popupData}
            onCancel={closePopup}
            footer={null}
            width={800}
          >
            <Table
              dataSource={popupData}
              pagination={false}
              columns={[
                {
                  title: t('day'),
                  dataIndex: 'date',
                  render: date => getDayOfWeek(date)
                },
                {
                  title: t('date'),
                  dataIndex: 'date',
                  render: date => formatDate(date)
                },
                {
                  title: t('time'),
                  render: (_, record) => 
                    `${formatTime(record.time)} - ${calculateEndTime(record.time, record.hours)}`
                },
                {
                  title: t('subjectName'),
                  dataIndex: 'subject'
                },
                {
                  title: t('teacherName'),
                  dataIndex: 'teacher'
                },
                {
                  title: t('hours'),
                  dataIndex: 'hours',
                  render: hours => `${hours} ${t('hr')}`
                }
              ]}
            />
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRoleAccess(StudentDashboard, ['student']);
