import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { 
  Layout, 
  Card, 
  Avatar, 
  Typography, 
  Table, 
  Space, 
  Button, 
  Tag, 
  Row, 
  Col,
  Input
} from 'antd';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  CalendarOutlined,
  BookOutlined,
  FileTextOutlined, 
  TrophyOutlined, 
  DeleteOutlined 
} from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { QRCodeCanvas } from 'qrcode.react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import thLocale from '@fullcalendar/core/locales/th';
import { useTranslation } from 'react-i18next';
import './Student.css';

const { Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;


function StudentDashboardAdmin() {
  // Existing state declarations remain the same
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [error, setError] = useState(null);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [testScores, setTestScores] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [newStudentInfo, setNewStudentInfo] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const itemsPerPage = 20;

  const API_URL = process.env.REACT_APP_API_URL;
  
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };
  

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const studentData = response.data;
        setStudent({
          ...studentData,
          photourl: studentData.photourl || 'https://via.placeholder.com/150',
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
        setError('Failed to fetch student data.');
      }
    };

    const fetchTestScores = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/test-scores/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTestScores(response.data);
      } catch (error) {
        console.error('Error fetching test scores:', error);
      }
    };

    const fetchStudentInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/student-info/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentInfo(response.data);
      } catch (error) {
        console.error('Error fetching student info:', error);
      }
    };

    const fetchAttendanceLogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceLogs(response.data);
      } catch (error) {
        console.error('Error fetching attendance logs:', error);
      }
    };

    const fetchBillingHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const { registrations } = response.data;
  
        // Sort schedules for each registration
        const sortedRegistrations = registrations.map(reg => {
          const sortedSchedule = reg.schedule.sort((a, b) => {
            const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm:ss');
            const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm:ss');
            return dateTimeA - dateTimeB;
          });
  
          return { ...reg, schedule: sortedSchedule };
        });
  
        // Sort registrations by the earliest class in their schedule
        sortedRegistrations.sort((a, b) => {
          const earliestA = a.schedule[0] ? moment(`${a.schedule[0].date} ${a.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          const earliestB = b.schedule[0] ? moment(`${b.schedule[0].date} ${b.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          return earliestA - earliestB;
        });
  
        setBillingHistory(sortedRegistrations);
      } catch (error) {
        console.error('Error fetching billing history:', error);
      }
    };

    fetchStudentData();
    fetchTestScores();
    fetchStudentInfo();
    fetchAttendanceLogs();
    fetchBillingHistory();
  }, [id, API_URL]);

  useEffect(() => {
    const fetchClassChangeLogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setClassChangeLogs(response.data);
      } catch (error) {
        console.error('Error fetching class change logs:', error);
      }
    };
  
    fetchClassChangeLogs();
  }, [API_URL]);


  const handleAddStudentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/student-info`, {
        student_id: id,
        info_text: newStudentInfo
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudentInfo([response.data, ...studentInfo]);
      setNewStudentInfo('');
    } catch (error) {
      console.error('Error adding student info:', error);
    }
  };


  const handleDeleteStudentInfo = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${API_URL}/student-info/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        setStudentInfo(studentInfo.filter(info => info.id !== id));
      }
    } catch (error) {
      console.error('Error deleting student info:', error);
    }
  };

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);
    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  }, [i18n.language]);


  const sortedAndFilteredSchedule = useMemo(() => {
    return billingHistory
      .flatMap(enrollment => 
        enrollment.schedule.map(schedule => ({ ...schedule, class_code: enrollment.class_code }))
      )
      .sort((a, b) => moment(a.date + ' ' + a.time, 'YYYY-MM-DD HH:mm:ss') - moment(b.date + ' ' + b.time, 'YYYY-MM-DD HH:mm:ss'));
  }, [billingHistory]);

  const paginatedSchedule = useMemo(() => {
    return sortedAndFilteredSchedule.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [sortedAndFilteredSchedule, currentPage, itemsPerPage]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
   

  const eventContent = (eventInfo) => {
    const { subject, teacher, classroom } = eventInfo.event.extendedProps;
    const startTime = eventInfo.event.startStr.split('T')[1].slice(0, 5);
    const endTime = eventInfo.event.endStr.split('T')[1].slice(0, 5);

    return (
      <div style={{ padding: '5px', fontSize: '0.8em' }}>
        <div style={{ fontWeight: 'bold' }}>{`${startTime} - ${endTime}`}</div>
        <div>{eventInfo.event.title}</div>
        <div>{subject}</div>
        <div>{teacher}</div>
        {classroom && <div>{`Room: ${classroom}`}</div>}
      </div>
    );
  };

  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;

  const getChangeReasonDisplay = (reason) => {
    switch (reason) {
      case 'admin_adjust':
        return { text: 'Admin', color: '#005181' };
      case 'cancelled_by_student':
        return { text: 'CL by S.', color: 'red' };
      case 'cancelled_by_teacher':
        return { text: 'CL by T.', color: 'orange' };
      case 'class_on_hold_by_student':
        return { text: 'On hold by S.', color: 'blue' };
      case 'class_on_hold_by_school':
        return { text: 'On hold by P.', color: 'green' };
      case 'cancel_same_day':
        return { text: 'Cancel Same Day', color: '#FF4500' }; // Added Cancel Same Day
      case 'make_up':
        return { text: 'Make Up', color: '#8A2BE2' }; // Added Make Up
      case 'others':
        return { text: 'Others', color: 'darkgrey' };
      default:
        return { text: reason || '', color: 'black' };
    }
};


  // Table columns for class schedule
  const scheduleColumns = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => formatDate(date)
    },
    {
      title: t('classCode'),
      dataIndex: 'class_code',
      key: 'class_code'
    },
    {
      title: t('classid'),
      dataIndex: 'classid',
      key: 'classid'
    },
    {
      title: t('subject'),
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: t('teacher'),
      dataIndex: 'teacher',
      key: 'teacher'
    },
    {
      title: t('time'),
      key: 'time',
      render: (text, record) => (
        `${formatTime(record.time)} - ${calculateEndTime(record.time, record.hours)}`
      )
    },
    {
      title: t('hours'),
      dataIndex: 'hours',
      key: 'hours',
      render: (hours) => `${hours} ${t('hr')}`
    },
    {
      title: t('classchange'),
      key: 'changereason',
      render: (text, record) => {
        const log = classChangeLogs.find(log => log.classid === record.classid);
        const { text: changeReasonText, color } = getChangeReasonDisplay(log?.changereason);
        return <Tag color={color}>{changeReasonText}</Tag>;
      }
    },
    {
      title: t('attendance'),
      key: 'attendance',
      render: (text, record) => {
        const attendanceLog = attendanceLogs.find(log => log.classid === record.classid);
        const status = attendanceLog?.status || 'Not Available';
        
        const statusStyles = {
          Present: {
            color: '#389e0d',
            backgroundColor: '#f6ffed',
            border: '1px solid #b7eb8f'
          },
          Absent: {
            color: '#cf1322',
            backgroundColor: '#fff1f0',
            border: '1px solid #ffa39e'
          },
          Late: {
            color: '#d46b08',
            backgroundColor: '#fff7e6',
            border: '1px solid #ffd591'
          },
          'Not Available': {
            color: '#8c8c8c',
            backgroundColor: '#f5f5f5',
            border: '1px solid #d9d9d9'
          }
        };
    
        return (
          <Tag
            style={{
              ...statusStyles[status],
              borderRadius: '12px',
              padding: '2px 12px',
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            {status}
          </Tag>
        );
      }
    },
    {
      title: t('homework'),
      key: 'homework',
      render: (text, record) => {
        const attendanceLog = attendanceLogs.find(log => log.classid === record.classid);
        const homework = attendanceLog?.homework || 'N/A';
        
        const homeworkStyles = {
          YES: {
            color: '#389e0d',
            backgroundColor: '#f6ffed',
            border: '1px solid #b7eb8f'
          },
          NO: {
            color: '#cf1322',
            backgroundColor: '#fff1f0',
            border: '1px solid #ffa39e'
          },
          'N/A': {
            color: '#8c8c8c',
            backgroundColor: '#f5f5f5',
            border: '1px solid #d9d9d9'
          }
        };
    
        return (
          <Tag
            style={{
              ...homeworkStyles[homework],
              borderRadius: '12px',
              padding: '2px 12px',
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            {homework}
          </Tag>
        );
      }
    },
    {
      title: t('comments'),
      key: 'comments',
      width: 250,
      render: (text, record) => {
        const attendanceLog = attendanceLogs.find(log => log.classid === record.classid);
        return (
          <div 
            style={{ 
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              minWidth: '150px',
              maxWidth: '250px',
              lineHeight: '1.5'
            }}
          >
            {attendanceLog?.comment || '-'}
          </div>
        );
      }
    }
  ];


  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;


  return (
    <Layout className="student-dashboard-layout">
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ padding: '24px' }}>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <Card 
                bordered={false}
                style={{ 
                  borderRadius: '16px',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
                }}
              >
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  padding: '24px'
                }}>
                  {/* Profile Header */}
                  <div style={{
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: '32px'
                  }}>
                    <Avatar 
                      size={120} 
                      src={student?.photourl} 
                      icon={<UserOutlined />}
                      style={{
                        border: '4px solid #fff',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                        marginBottom: '16px'
                      }}
                    />
                    <Title level={3} style={{ margin: '8px 0' }}>
                      {student?.firstname} {student?.lastname}
                    </Title>
                    <Tag color="blue" style={{ padding: '4px 12px' }}>
                      {student?.schoolid}
                    </Tag>
                  </div>

                  {/* Profile Details */}
                  <div style={{ width: '100%' }}>
                    {[
                      { 
                        icon: <MailOutlined style={{ color: '#1890ff' }} />, 
                        label: t('email'), 
                        value: student?.email 
                      },
                      { 
                        icon: <PhoneOutlined style={{ color: '#52c41a' }} />, 
                        label: t('phone'), 
                        value: student?.phone 
                      },
                      { 
                        icon: <HomeOutlined style={{ color: '#faad14' }} />, 
                        label: t('address'), 
                        value: student?.address 
                      },
                      { 
                        icon: <CalendarOutlined style={{ color: '#eb2f96' }} />, 
                        label: t('dob'), 
                        value: student?.date_of_birth && formatDate(student.date_of_birth)
                      },
                      { 
                        icon: <BookOutlined style={{ color: '#722ed1' }} />, 
                        label: t('currentEducationLevel'), 
                        value: student?.currenteducationlevel 
                      }
                    ].map((item, index) => (
                      <div 
                        key={index}
                        style={{
                          padding: '16px',
                          marginBottom: '12px',
                          background: '#f5f5f5',
                          borderRadius: '12px',
                          transition: 'all 0.3s ease'
                        }}
                        onMouseEnter={e => e.currentTarget.style.transform = 'translateX(8px)'}
                        onMouseLeave={e => e.currentTarget.style.transform = 'translateX(0)'}
                      >
                        <Space align="center">
                          <div style={{
                            padding: '8px',
                            borderRadius: '8px',
                            background: '#fff',
                            marginRight: '12px'
                          }}>
                            {item.icon}
                          </div>
                          <div>
                            <Text type="secondary" style={{ fontSize: '12px', display: 'block' }}>
                              {item.label}
                            </Text>
                            <Text strong style={{ fontSize: '14px' }}>
                              {item.value}
                            </Text>
                          </div>
                        </Space>
                      </div>
                    ))}
                  </div>

                  {/* QR Code Section */}
                  <div style={{ 
                    marginTop: '24px',
                    padding: '16px',
                    background: '#f5f5f5',
                    borderRadius: '12px',
                    textAlign: 'center'
                  }}>
                    <Text type="secondary" style={{ display: 'block', marginBottom: '12px' }}>
                      {t('Student ID')}
                    </Text>
                    <QRCodeCanvas 
                      value={student?.schoolid || ''} 
                      size={120}
                      style={{
                        padding: '8px',
                        background: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.06)'
                      }}
                    />
                  </div>
                </div>
              </Card>
            </Col>

            <Col xs={24} md={16}>
              <Card title={t('calendar')} style={{ marginBottom: '24px' }}>
              <FullCalendar
  plugins={[dayGridPlugin, timeGridPlugin]}
  initialView="dayGridMonth"
  locale={i18n.language === 'th' ? thLocale : 'en'}
  events={billingHistory.flatMap(enrollment =>
    enrollment.schedule.map(cls => ({
      title: `${enrollment.class_code}`,
      start: `${cls.date}T${cls.time}`,
      end: `${cls.date}T${calculateEndTime(cls.time, cls.hours)}`,
      backgroundColor: '#005181',
      borderColor: '#005181',
      textColor: '#ffffff',
      display: 'block',        // Add this to ensure block display
      extendedProps: {
        subject: cls.subject,
        teacher: cls.teacher,
        classroom: cls.classroom,
      },
    }))
  )}
  eventContent={eventContent}
  headerToolbar={{
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek',
  }}
  height="auto"
  eventDisplay="block"       // Add this to ensure block display
  dayCellClassNames="calendar-cell" // Add custom class for day cells
  eventClassNames="calendar-event" // Add custom class for events
/>
              </Card>
              </Col>
          </Row>

          {/* Second Row - Test Scores and Student Info */}
          <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
            {/* Test Scores Column */}
            <Col xs={24} md={12}>
  <Card 
    title={
      <Space>
        <TrophyOutlined style={{ color: '#1890ff' }} />
        {t('testScores')}
      </Space>
    }
    style={{ height: '100%' }}
  >
    <div style={{ 
      display: 'grid', 
      gap: '16px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))'
    }}>
      {testScores.map((score, index) => (
        <Card 
          key={index}
          size="small"
          style={{
            background: '#f8f9fa',
            borderRadius: '12px',
            border: '1px solid #e8e8e8'
          }}
        >
          <div style={{ marginBottom: '16px', textAlign: 'center' }}>
            <Tag 
              color="blue" 
              style={{ 
                marginBottom: '8px',
                padding: '4px 12px',
                fontSize: '16px',
                borderRadius: '6px',
                textTransform: 'uppercase',
                fontWeight: '500'
              }}
            >
              {score.type}
            </Tag>
            {score.sub_type && (
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                marginTop: '12px'
              }}>
                <Text type="secondary">{t('Subject')}:</Text>
                <Text strong>{score.sub_type}</Text>
              </div>
            )}
          </div>

          <Space direction="vertical" style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text type="secondary">{t('date')}:</Text>
              <Text strong>{formatDate(score.test_date)}</Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text type="secondary">{t('score')}:</Text>
              <Text strong>{score.score}</Text>
            </div>
            {score.test_name && (
              <div style={{ 
                marginTop: '8px',
                padding: '8px',
                background: '#fff',
                borderRadius: '8px',
                border: '1px solid #f0f0f0'
              }}>
                <Text type="secondary" style={{ fontSize: '12px', marginRight: '3px' }}>{t('Details')}:</Text>
                <Text>{score.test_name}</Text>
              </div>
            )}
            {score.note && (
              <div style={{ 
                marginTop: '8px',
                padding: '8px',
                background: '#fff',
                borderRadius: '8px',
                border: '1px solid #f0f0f0'
              }}>
                <Text type="secondary" style={{ fontSize: '12px', marginRight: '3px' }}>{t('note')}:</Text>
                <Text>{score.note}</Text>
              </div>
            )}
          </Space>
        </Card>
      ))}
    </div>
  </Card>
</Col>

            <Col xs={24} md={12}>
  <Card 
    title={
      <Space>
        <FileTextOutlined style={{ color: '#1890ff' }} />
        {t('studentInfo')}
      </Space>
    }
    style={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}
    bodyStyle={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    {/* Content Area */}
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      flex: 1,
      overflowY: 'auto',
      marginBottom: '16px'
    }}>
      {studentInfo.map((info, index) => (
        <Card 
          key={index}
          size="small"
          style={{
            background: '#f8f9fa',
            borderRadius: '12px',
            border: '1px solid #e8e8e8'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <Text style={{ whiteSpace: 'pre-wrap' }}>
                {info.info_text}
              </Text>
              <div style={{ marginTop: '8px' }}>
                <Space size="small">
                  <Tag color="blue">{info.created_by_nickname}</Tag>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    {formatDate(info.created_at)}
                  </Text>
                </Space>
              </div>
            </div>
            <Button 
              type="text" 
              danger
              icon={<DeleteOutlined />} 
              onClick={() => handleDeleteStudentInfo(info.id)}
              style={{ marginLeft: '12px' }}
            />
          </div>
        </Card>
      ))}
    </div>

    {/* Input Area */}
    <div style={{
      marginTop: 'auto',
      borderTop: '1px solid #f0f0f0',
      paddingTop: '16px',
      background: '#fff'
    }}>
      <Space.Compact style={{ width: '100%' }}>
        <TextArea
          value={newStudentInfo}
          onChange={(e) => setNewStudentInfo(e.target.value)}
          placeholder={t('enterStudentInfo')}
          autoSize={{ minRows: 1, maxRows: 4 }}
          style={{ borderRadius: '6px 0 0 6px' }}
        />
        <Button 
          type="primary"
          onClick={handleAddStudentInfo}
          disabled={!newStudentInfo.trim()}
          style={{ 
            height: 'auto',
            borderRadius: '0 6px 6px 0'
          }}
        >
          {t('add')}
        </Button>
      </Space.Compact>
    </div>
  </Card>
</Col>
          </Row>

          {/* Third Row - Class Schedule */}
          <Row style={{ marginTop: '24px' }}>
            <Col span={24}>
              <Card title={t('classSchedule')}>
                <Table
                  columns={scheduleColumns}
                  dataSource={paginatedSchedule}
                  pagination={{
                    current: currentPage,
                    total: sortedAndFilteredSchedule.length,
                    pageSize: itemsPerPage,
                    onChange: handlePageChange
                  }}
                  scroll={{ x: true }}
                />
              </Card>
            </Col>
          </Row>

          {/* Fourth Row - Current Enrollments */}
          <Row style={{ marginTop: '24px' }}>
            <Col span={24}>
              <Card title={t('currentEnrollments')}>
                <Row gutter={[16, 16]}>
                  {billingHistory.map((enrollment, index) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={index}>
                      <Card 
                        hoverable
                        style={{ height: '100%' }}
                      >
                        <Title level={4}>{enrollment.class_code}</Title>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Text type="secondary">{t('Subjects')}:</Text>
                          {enrollment.subjects.map((subject, idx) => (
                            <Tag key={idx} color="blue">{subject.title}</Tag>
                          ))}
                          <Text>
                            {t('Total Hours')}: {enrollment.total_hours} {t('hrs')}
                          </Text>
                          <Link to={`/classdetailsstudent/${enrollment.groupid}`}>
                            <Button type="primary" block>
                              {t('viewDetails')}
                            </Button>
                          </Link>
                        </Space>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRoleAccess(StudentDashboardAdmin, ['admin', 'superadmin']);