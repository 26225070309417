import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import './Teacher.css';
import { useTranslation } from 'react-i18next';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) {
    return 'Invalid Date';
  }
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};

const TeacherDetailsPage = () => {
  const { teacherId } = useParams();
  const { t } = useTranslation(); // useTranslation hook
  const [teacher, setTeacher] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tempMonth, setTempMonth] = useState(new Date().getMonth() + 1);
  const [tempYear, setTempYear] = useState(new Date().getFullYear());
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [hourlyRates, setHourlyRates] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [withholdingTax, setWithholdingTax] = useState(false); 
  const [showConfirmModal, setShowConfirmModal] = useState(false); 
  const [confirmData, setConfirmData] = useState({}); 
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [remarks, setRemarks] = useState({});
  
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchTeacher = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTeacher(response.data);
    } catch (error) {
      console.error(t('errorFetchingTeacher'), error);
    }
  }, [teacherId, API_URL, t]);

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClasses(response.data);
      filterClasses(response.data, selectedMonth, selectedYear);
    } catch (error) {
      console.error(t('errorFetchingClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  const fetchApprovedClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teacher-hours/${teacherId}/approved`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          period: `${selectedYear}-${selectedMonth < 10 ? '0' + selectedMonth : selectedMonth}`
        }
      });
      if (response.data.length > 0) {
        setIsApproved(true);
        setFilteredClasses(response.data);
        const rates = {};
        response.data.forEach(({ class_id, hourly_rate }) => {
          rates[class_id] = hourly_rate;
        });
        setHourlyRates(rates);
      } else {
        setIsApproved(false);
      }
    } catch (error) {
      console.error(t('errorFetchingApprovedClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  const fetchClassChangeLogs = useCallback(async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setClassChangeLogs(response.data);
    } catch (error) {
        console.error('Error fetching class change logs:', error);
    }
}, [API_URL]);

  useEffect(() => {
    fetchTeacher();
    fetchClasses();
    fetchApprovedClasses();
    fetchClassChangeLogs(); 
  }, [fetchTeacher, fetchClasses, fetchApprovedClasses, fetchClassChangeLogs]);

  const filterClasses = (classes, month, year) => {
    const filtered = classes.filter(cls => {
      const classDate = new Date(cls.date);
      return classDate.getMonth() + 1 === month && classDate.getFullYear() === year;
    });
    setFilteredClasses(filtered);
  };

  const handleMonthChange = (event) => {
    const month = parseInt(event.target.value, 10);
    setTempMonth(month);
  };

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value, 10);
    setTempYear(year);
  };

  const handleSelect = () => {
    setSelectedMonth(tempMonth);
    setSelectedYear(tempYear);
    filterClasses(classes, tempMonth, tempYear);
  };

  const handleRemarkChange = (classId, value) => {
    setRemarks((prevRemarks) => ({
        ...prevRemarks,
        [classId]: value,
    }));
};

  const convertScheduleHourToDecimal = (scheduleHour) => {
    if (!scheduleHour) return 0;
    const [hours, minutes] = scheduleHour.split('h').map(part => part.trim().replace('m', ''));
    const hoursDecimal = parseInt(hours, 10) || 0;
    const minutesDecimal = parseInt(minutes, 10) / 60 || 0;
    return hoursDecimal + minutesDecimal;
  };

  const formatDecimalToHoursMinutes = (decimalHours) => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const handleApprove = () => {
    // Prepare data for approved classes
    const approvedClassesData = filteredClasses.map(cls => ({
        classId: cls.classid,
        hourlyRate: hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
        hoursWorked: convertScheduleHourToDecimal(cls.schedule_hour),
        remark: remarks[cls.classid] || '', // Include the remark for each class
    }));

    const totalAmountWithTax = withholdingTax ? totalAmount * 0.97 : totalAmount;
    setConfirmData({
        teacherName: `${teacher.firstname} ${teacher.lastname}`,
        period: `${selectedMonth}/${selectedYear}`,
        totalAmount: formatAmount(totalAmountWithTax),
        approvedClasses: approvedClassesData, // Add the approved classes data here
    });

    setShowConfirmModal(true);
};


const confirmApproval = async () => {
  try {
    const token = localStorage.getItem('token');
    await axios.post(`${API_URL}/teacher-hours/${teacherId}/approve`, {
      period: `${selectedYear}-${selectedMonth < 10 ? '0' + selectedMonth : selectedMonth}`,
      approvedClasses: filteredClasses.map(cls => {
        const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
        return {
          classId: cls.classid,
          hourlyRate: hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
          hoursWorked: scheduleHourDecimal.toFixed(2)
        };
      }),
      totalAmount: confirmData.totalAmount,
      withholdingTaxApplied: withholdingTax 
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    setShowConfirmation(true);
    fetchApprovedClasses();
    setShowConfirmModal(false);
  } catch (error) {
    console.error(t('errorApprovingHours'), error);
  }
};

const getChangeReasonDisplay = (reason) => {
  switch (reason) {
      case 'admin_adjust':
          return { text: 'Admin', color: '#005181' };
      case 'cancelled_by_student':
          return { text: 'CL by S.', color: 'red' };
      case 'cancelled_by_teacher':
          return { text: 'CL by T.', color: 'orange' };
      case 'class_on_hold_by_student':
          return { text: 'On hold by S.', color: 'blue' };
      case 'class_on_hold_by_school':
          return { text: 'On hold by P.', color: 'green' };
      case 'cancel_same_day':
          return { text: 'Cancel Same Day', color: '#FF4500' }; // Added Cancel Same Day
      case 'make_up':
          return { text: 'Make Up', color: '#8A2BE2' }; // Added Make Up
      case 'others':
          return { text: 'Others', color: 'darkgrey' };
      default:
          return { text: reason, color: 'black' };
  }
};


const handleExportCSV = () => {
  const csvData = filteredClasses.map(cls => ({
      'Teacher ID': teacher ? teacher.userid : '',
      'Teacher Name': teacher ? `${teacher.firstname} ${teacher.lastname}` : '',
      'Class Code': cls.class_code,
      'Class ID': cls.classid,
      'Subject Name': cls.subject_name,
      'Date': formatDate(cls.date),
      'Schedule Hour': cls.schedule_hour,
      'Hourly Rate': hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
      'Total Hours': convertScheduleHourToDecimal(cls.schedule_hour).toFixed(2),
      'Total Amount': formatAmount(convertScheduleHourToDecimal(cls.schedule_hour) * (hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0))),
      'Status': cls.status,
      'Payout': cls.payout
  }));
  return csvData;
};


  const handleRateChange = (classId, value) => {
    setHourlyRates({
      ...hourlyRates,
      [classId]: parseFloat(value) || 0
    });
  };

  const totalScheduleHours = filteredClasses.reduce((acc, cls) => acc + convertScheduleHourToDecimal(cls.schedule_hour), 0);
  const totalAmount = filteredClasses.reduce((acc, cls) => {
    const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
    const hourlyRate = hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0);
    return acc + (scheduleHourDecimal * hourlyRate);
  }, 0);

  const formatAmount = (amount) => {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const allClassesApproved = filteredClasses.every(cls => cls.status === 'approved');

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="teacher-details-container">
        <div className="teacher-hours-content">
          <h1>{t('teacherDetails')}</h1>
          {teacher && (
            <>
              <h3> ({teacher.nickname}) {teacher.firstname} {teacher.lastname} </h3>
              <p>{t('teacherID')}: {teacher.userid}</p>
              <p>{t('currentHourlyRate')}: {teacher.hoursrate}</p>
              <div className="datepicker">
                <label>
                  <select value={tempMonth} onChange={handleMonthChange}>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                      <option key={month} value={month}>
                        {new Date(0, month - 1).toLocaleString('default', { month: 'long' })}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  <select value={tempYear} onChange={handleYearChange}>
                    {Array.from({ length: 5 }, (_, i) => 2024 + i).map(year => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </label>
                <button className="search-button" onClick={handleSelect}>
                  <FontAwesomeIcon icon={faSearch} /> {t('search')}
                </button>
                <CSVLink
                  data={handleExportCSV()}
                  filename={`approved_hours_${teacherId}_${selectedMonth}_${selectedYear}.csv`}
                >
                  <button className="csv-button">
                    <FontAwesomeIcon icon={faFileExcel} /> {t('exportToCSV')}
                  </button>
                </CSVLink>
              </div>

              <div className="table-scroll-container" style={{ overflowX: 'auto',  maxWidth: '1290px'}}>
              <table>
                <thead>
                  <tr>
                    <th>{t('classCode')}</th>
                    <th>{t('id')}</th>
                    <th>{t('subjectName')}</th>
                    <th>{t('type')}</th>
                    <th>{t('date')}</th>
                    <th>{t('hours')}</th>
                    <th>{t('rate')}</th>
                    <th>{t('change')}</th>
                    <th>{t('note')}</th>
                    <th>{t('total')}</th>
                    <th>{t('confirmteach')}</th>
                    <th>{t('admin')}</th>
                    <th>{t('status')}</th>
                    <th>{t('remark')}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClasses.map(cls => {
                    const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
                    const hourlyRate = hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0);
                    const totalAmount = scheduleHourDecimal * hourlyRate;
                    const classChangeLog = classChangeLogs.find(log => log.classid === cls.classid);
                    const changeReason = getChangeReasonDisplay(classChangeLog?.changereason);
                    
                    return (
                      <tr key={cls.classid}>
                        <td>{cls.class_code}</td>
                        <td>{cls.classid}</td>
                        <td style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
  {cls.subject_name}
</td>

                        <td>{cls.isprivate ? t('private') : t('group')}</td>
                        <td>{formatDate(cls.date)}</td>
                        <td>{cls.schedule_hour}</td>
                        <td>
                          <input
                            type="number"
                            value={hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0)}
                            onChange={(e) => handleRateChange(cls.classid, e.target.value)}
                            disabled={isApproved}
                          />
                        </td>
                        <td style={{ color: changeReason.color }}>{changeReason.text}</td>
                        <td>{classChangeLog?.note || '-'}</td>
                        <td>{isNaN(totalAmount) ? 'N/A' : formatAmount(totalAmount)}</td>
                        <td className={cls.status === 'approved' ? 'status-approved' : 'status-pending'}>
                          {cls.status === 'approved' ? t('HOURSAPPROVED') : t('confirmpending')}
                        </td>
                        <td className={cls.adminapprove === 'adminconfirm' ? 'admin-status-approved' : 'admin-status-pending'}>
                          {cls.adminapprove === 'adminconfirm' ? t('adminconfirmed') : t('confirmpending')}
                        </td>
                        <td className={cls.payout === 'pending' ? 'payout-pending' : 'payout-paid'}>
                          {cls.payout === 'pending' ? t('Pending') : t('Paid')}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="remarkbox"
                            value={remarks[cls.classid] || ''} 
                            onChange={(e) => handleRemarkChange(cls.classid, e.target.value)}
                            placeholder={t('enterRemark')}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan="3"><strong>{t('total')}</strong></td>
                    <td></td>
                    <td></td>
                    <td><strong>{formatDecimalToHoursMinutes(totalScheduleHours)}</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><h3>{formatAmount(totalAmount)}</h3></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
              {allClassesApproved && !isApproved && (
                  <button onClick={handleApprove}>{t('approveHoursAndPayment')}</button>
              )}

              {showConfirmation && (
                <div className="confirmation-popup">
                  <p>{t('teacherHoursApprovalSaved')}</p>
                  <button onClick={() => setShowConfirmation(false)}>{t('close')}</button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showConfirmModal && (
        <div className="teacher-payment-modal">
          <div className="teacher-payment-modal-content">
            <h2>{t('confirm_payment')}</h2>
            <p>{t('teacherName')}: {confirmData.teacherName}</p>
            <p>{t('period')}: {confirmData.period}</p>
            <p>{t('nettotal')}: {confirmData.totalAmount}</p>
            
            <label>
              <input 
                type="checkbox" 
                checked={withholdingTax} 
                onChange={() => setWithholdingTax(!withholdingTax)} 
              /> 
              {t('sumWithholdingTax')}
            </label>
      
            <div className="modal-actions">
            <button onClick={() => setShowConfirmModal(false)}>{t('cancel')}</button>
              <button onClick={confirmApproval}>{t('confirm')}</button>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRoleAccess(TeacherDetailsPage, ['superadmin']);
